<template>
  <div class="bg-white px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-3xl text-base/7 text-gray-700">
      <h1 class="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
        Privacy Policy
      </h1>
      <p class="mt-6 text-xl/8">
        In questa pagina si descrivono le modalità di gestione del sito internet
        <a href="https://www.favaclaudio.com/" class="text-indigo-400 hover:underline">www.favaclaudio.com</a>
        in riferimento al trattamento dei dati personali degli utenti che lo consultano.
        Si tratta di un’informativa per il trattamento dei dati personali resa ai sensi
        dell’art. 13 del GDPR 2016/679 (di seguito Regolamento Europeo Privacy) per gli utenti
        che usufruiscono dei servizi del nostro sito Internet. L’informativa è resa solo per
        il presente sito e non per altri siti web eventualmente consultati tramite link.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Titolare del trattamento
      </h2>
      <p class="mt-6">
        Il Titolare del trattamento dei dati personali è:
        <strong>Arch. Claudio Fava</strong><br>
        P.IVA 10620700012<br>
        VIA FREJUS, 41 - 10139 TORINO (TO - IT)<br>
        Indirizzo email: <a href="mailto:info@favaclaudio.com" class="text-indigo-400 hover:underline">info@favaclaudio.com</a>
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Dati di navigazione
      </h2>
      <p class="mt-6">
        I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
        Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Finalità del trattamento
      </h2>
      <p class="mt-6">
        I dati personali forniti dall’utente saranno trattati per finalità di statistica
        (Google Analytics con IP anonimo) e per l’invio di newsletter.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Luogo del trattamento
      </h2>
      <p class="mt-6">
        I trattamenti connessi ai servizi web di questo sito hanno luogo presso la sede del
        titolare e sono curati solo da personale autorizzato.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Modalità di trattamento dei dati
      </h2>
      <p class="mt-6">
        I dati personali raccolti, sono trattati con strumenti informatici automatizzati. Adeguate misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Comunicazione e diffusione dei dati
      </h2>
      <p class="mt-6">
        I dati personali forniti dall’utente non saranno diffusi. I suoi dati non saranno trasferiti fuori EU. I dati personali potranno essere comunicati e trattati dai soggetti preposti della società, con apposito conferimento e da terzi in qualità di responsabili esterni al trattamento o autonomi titolari del trattamento che erogano servizi strumentali a soddisfare la richiesta dell’utente. L’elenco aggiornato è disponibile presso la sede legale della società.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Cookies
      </h2>
      <p class="mt-6">
        Per cookies si intende un elemento testuale che viene inserito nel disco fisso di un computer solo in seguito ad autorizzazione. I cookies hanno la funzione di ricordare lo stato dell’utente e quindi facilitare la navigazione.

        Questi possono essere abilitati direttamente sul browser che si sta utilizzando (internet explorer, google chrome, mozzilla forefox, safari etc). Per far ciò occorre accedere alla sezione strumenti del proprio browser, cliccare sulla sotto sezione privacy.

        L’abilitazione dei cookies permette all’utente di essere immediatamente riconosciuto sul nostro sito in ogni visita successiva alla prima, e quindi una più agevole e scorrevole navigazione all’interno del sito, oltre alla possibilità di usufruire di un servizio dedicato.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Diritti degli interessati
      </h2>
      <p class="mt-6">
        I soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento di revocare il proprio; di chiedere l’accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento dei dati personali che lo riguardano e di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati e le richieste di diritto all’oblio; di proporre reclamo all’Autorità Garante per la protezione dei dati personali italiana.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Condizioni per il consenso dei minori
      </h2>
      <p class="mt-6">
        Se l'utente ha meno di 16 anni, è necessario che il consenso al trattamento dei dati
        sia fornito da un genitore o tutore legale.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Periodo di conservazione dei dati
      </h2>
      <p class="mt-6">
        I dati saranno conservati per il tempo necessario all’espletamento della richiesta
        dell’utente e comunque per un periodo massimo stabilito dalla legge.
      </p>

      <h2 class="mt-16 text-pretty text-3xl font-semibold tracking-tight text-gray-900">
        Modifiche all’informativa
      </h2>
      <p class="mt-6">
        Il Titolare si riserva il diritto di modificare la presente informativa privacy in
        qualsiasi momento. Si invita l’utente a consultare periodicamente questa pagina per
        eventuali aggiornamenti.
      </p>
    </div>
  </div>
</template>
